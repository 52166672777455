<template>
  <div>
    <div class="home-bg1">
        <div class="plan_banner wr ">
            <div class="bg" style=" background-image: url('https://www.supcon.com/bocupload/2023/08/03/1691046324891x3hoh.jpg');"></div>
            <div class="txt">
                <div class="txt-title col-fff tit-24">
                    企业理念/ENTERPRISE PHILOSOPHY
                </div>
                <div class="tit-58 hnb col-fff wow fadeInUp  animated animated animated" style="visibility: visible;">
                    加速企业信息化进程<br>助推制造智能化升级
                </div>
            </div>
        </div>
        <div class="content-a wr">
            <div class="w1520">
                <div class="list2 wow fadeInUp animated animated animated" style="visibility: visible;">
                <div class="tit-64 hnb col-000">
                    公司概况            </div>
                <div class="tit-16 col-333">
                    <p style="line-height: 1.75em; text-align: justify;"><br></p><p style=" font-family: 'Microsoft YaHei Light';"> 西安迪倍特信息科技有限公司成立于2020年，总部位于陕西西安高新区。作为一家高新技术企业，我们专注于物联网、大数据、数字孪生技术及自动化控制解决方案。公司利用先进的传感器、无线通信和数据采集技术，推动设备与系统的无缝连接，构建智能化物联网。

我们拥有强大的数据处理与分析能力，致力于挖掘数据价值，为企业提供精准的市场洞察、运营优化及决策支持。同时，通过数字孪生技术，我们结合物理世界与虚拟数字模型，实现设施与设备的全生命周期管理，提前识别潜在问题，降低运营风险，提升决策效率。

自动化控制技术是我们帮助企业降本增效的重要手段，通过智能控制系统提升生产效率、减少人工成本并降低操作风险。目前，公司已在智慧水务、智慧农业、智慧工厂、智慧交通等多个领域成功应用我们的解决方案，助力客户实现业务流程的数字化、自动化与智能化。</p><p style="line-height: 1.75em; text-align: justify;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;"></span><br></p></div>
                </div>
            </div>   
        </div>
        <div class="content-b wr ">
            <div class="w1520">
                <div class="tit-64 hnb text-center lh1 wow fadeInUp animated animated animated" style="visibility: visible;">企业文化</div>
                <div class="list wr f-cb wow fadeInUp animated animated animated" style="visibility: visible;">
                    <div class="li">
                        <div class="img">
                            <div class="bg" style=" background-image: url('https://www.supcon.com/bocupload/2022/12/12/16708353842248lf860.jpg');"></div>
                        </div>
                        <div class="tit-30 hnb">客户至上</div>
                        <div class="tit-20 hnm col-444">公司的首要使命</div>
                        <div class="tit-15  col-666">
                            <p style="text-align: justify;">我们始终将客户的需求放在首位，致力于为客户创造最大价值，保持真诚的沟通与高效的服务，不断提升客户满意度和信任度。</p>                        </div>
                        </div>
                    <div class="li">
                        <div class="img">
                            <div class="bg" style=" background-image: url('https://www.supcon.com/bocupload/2022/12/13/16708948394283jfgcm.jpg');"></div>
                        </div>
                        <div class="tit-30 hnb">创新驱动</div>
                        <div class="tit-20 hnm col-444">企业的经营理念</div>
                        <div class="tit-15  col-666">
                            <p style="text-align: justify;">我们倡导技术创新，鼓励员工不断探索和应用新技术，推动公司在行业中的领先地位。通过创新实现发展，以技术的进步提升企业竞争力。</p>
                        </div>
                    </div>
                    <div class="li">
                        <div class="img">
                            <div class="bg" style=" background-image: url('https://www.supcon.com/bocupload/2022/12/13/16708946791566y8d98.jpg');"></div>
                        </div>
                        <div class="tit-30 hnb">人才为本</div>
                        <div class="tit-20 hnm col-444">员工的立身之本</div>
                        <div class="tit-15  col-666">
                            <p style="text-align: justify;">我们高度重视人才的培养与发展，注重为员工提供广阔的发展平台和成长机会，激发员工的潜力，实现公司和员工的共同成长。</p>
                        </div>
                    </div>
                    <div class="li">
                        <div class="img">
                            <div class="bg" style=" background-image: url('https://www.supcon.com/bocupload/2022/12/13/167089469288447va.jpg');"></div>
                        </div>
                        <div class="tit-30 hnb">责任担当</div>
                        <div class="tit-20 hnm col-444">团队的发展信条</div>
                        <div class="tit-15  col-666">
                            <p style="text-align: justify;">我们坚信行动胜于言辞，以行动来兑现承诺，以专业的水平和卓越的服务赢得市场尊重。每个员工都应当对自己的工作、团队以及公司承担责任，共同推动公司的长远发展。</p>
                        </div>
                    </div>                 
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        
    },
    mounted() {
        
    }
}
</script>
<style lang="less" scoped>
// web端样式
.home-bg1 {
    user-select: none;
    width: 100%;
    background-image: url('../../img/bg2.jpg');
    background-size: cover;
    position: relative;
    overflow: auto;
    .plan_banner {
        height: 53.75rem;
        .txt {
            position: absolute;
            left: 10%;
            top: 33%;
            .txt-title {
                margin-bottom: 20px;
                font-style: italic;
            }
            .hnb {
                line-height: 1.2;
            }
        }
    }
    .content-a {
        padding: 6.25rem 0 5.81rem;
        padding-bottom: 5.25rem;
        .list2 {
            margin-top: 6.06rem;
            padding: 4.38rem 4.69rem;
            border-radius: 6px;
            /* background-color: #fff; */
            background-image: url(https://www.supcon.com/bocstatic/web/css/../img/zw/5.png);
            background-size: 100% 100%;
            width: 60rem;
            position: relative;
        }
    }
    .content-b {
        padding: 0 0 6.25rem;
        .list {
            margin-top: 2.5rem;
            box-shadow: 0px 1px 13px 1px #0000001a;
            .li {
                width: 25%;
                height: 35rem;
                background-color: #fff;
                float: left;
                padding: 4.38rem 3.13rem;
                text-align: center;
                transition: all 0.5s;
                .img {
                    width: 9.06rem;
                    height: 9.06rem;
                    border-radius: 50%;
                    overflow: hidden;
                    margin: 0 auto;
                }
                .hnb {
                    line-height: 1;
                    margin-top: 2.75rem;
                }
                .col-666 {
                    line-height: 1.8;
                    text-align: left;
                    margin-top: .75rem;
                }
            }
            .li:nth-child(1), .li:nth-child(3) {
                background-color: #f0f4fd;
            }
        }
    }
    .cycle-wrapper {
        padding: 90px 30px 0;
        color: #FFFFFF;
        .content {
            padding: 5px;
            margin: 0 35px;
            .con-title {
                font-size: 16px;
                width: 100px;
                height: 30px;
                background: #ffffff;
                border-radius: 15px;
                color: rgb(103, 103, 103);
                text-align: center;
                line-height: 30px;
                margin: 10px 0 20px;
            }
            .con-text {
                font-size: 16px;
                line-height: 35px;
                text-indent: 30px;
                letter-spacing: 2px;
            }
        }
    }
}

// 移动端样式
@media screen and (max-width:650px) {
    .home-bg1 {
        margin-top: -44px;
        .cycle-wrapper {
            padding: 44px 0px;
            .content {
                padding: 5px;
                margin: 0 35px;
                .con-title {
                    font-size: 14px;
                }
                .con-text {
                    font-size: 14px;
                    line-height: 35px;
                }
            }
        }
    }
}
</style>
<style>
/* 移动端样式 */
@media screen and (max-width:650px) {
    .el-breadcrumb__inner {
        font-size: 14px !important;
    }
    .el-breadcrumb__separator {
        font-size: 14px !important;
    }
}
</style>